@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');


:root {
  --purple: #a48fb5;
  --light-blue: #77b8b0;
  --blue: #77b3ca;
  --red: #d97f7e;
  --orange: #f3a65a;
  --yellow-fulgeas: #fdc400;
  --raleway: 'Raleway', sans-serif;
  --roboto: 'Roboto', sans-serif;
  --grey: #e2e2e2;
  --dark-blue: #468bc1;
  --variant-blue: #0081C6;
  --dark-grey: #505050;
  --light-grey: #F4F4F4;
  --variant-grey: #818181;
  --grey-second: #AEAEAE;
  --green: #69c95a;
  --variant-red: #e44666;
  --dark-grey-variant: #9f9f9f;
  --bs-dark: #212529;

}



::selection {
  background-color: var(--yellow-fulgeas);
  color: black;
}

.color-variant-grey {
  color: var(--variant-grey);
}

.color-yellow {
  color: var(--yellow-fulgeas);
}

* {
  font-family: var(--roboto);
  margin: 0;
  padding: 0;
  list-style: none;

}



html {
  scroll-behavior: smooth;
}



.color-light-grey {
  color: var(--grey-second)
}

.bg-dark-grey {
  background-color: var(--dark-grey-variant);
}

.container-large {
  max-width: 1300px !important;
  width: auto;
}


body {
  background-color: white;
}

.arrow-up {
  transform: rotate(180deg);
  transition: all 250ms;
}

.arrow {
  transition: all 250ms;
}

p {
  margin: 0;

}

/* Card nella Dashboard */


.navbar {
  margin: 0;
}

/* Prime cards */

.prime-cards {
  max-width: 300px;
}

.bg-prima-card {
  background-color: var(--purple);
}

.bg-seconda-card {
  background-color: var(--light-blue);
}

.bg-terza-card {
  background-color: var(--blue);
}

.bg-quarta-card {
  background-color: var(--red);
}


/* Seconde cards */

.last-news-cards {
  max-width: 430px;
  height: 300px;
}


.bg-notifiche {
  background-color: var(--orange);
}

.border-notifiche {
  border: 1px var(--orange) solid;
}

.bg-progetti {
  background-color: var(--purple);
}



.bg-inactive {
  background-color: var(--dark-grey);
}

/* Cards Statistiche  */

.statistics-cards {
  width: 650px;
  height: 600px;
}


/* Il select */

.selector {
  width: 80px;
}

.fs-table {
  font-size: 12px;
}

/* Vari background color */

.bg-blue {
  background-color: var(--blue);
}

.bg-grey {
  background-color: var(--grey);
}

.rounded-mobile {
  border-radius: 20px 20px 20px 20px;
}


.bg-light-grey {
  background-color: var(--light-grey);
}

.bg-grey-second {
  background-color: var(--grey-second);
}

textarea:focus {
  border: 0;
  outline: transparent;
}

.text-descr {
  min-height: 400px;
  max-height: 400px;
}

.allegati {
  border: 1px var(--grey-second) solid;
}


.fc-sticky {
  color: white;
}

.fc-event-time,
.fc-event-title {
  color: black;
}

.fc-daygrid-day-number,
.fc-col-header-cell-cushion {
  color: black;
  text-decoration: none;
}

.fc-daygrid {
  height: auto;
  max-height: 600px;
  overflow: hidden;
}


.fc-icon-chevron-left,
.fc-icon-chevron-right {
  margin: 0;

}

.ciao {
  margin: 0;
}

.fc-view-harness .fc-view-harness-active {
  height: 0;
}

.profile-chat-image {
  border-radius: 100%;
  overflow: hidden;
  width: 70px;
  height: 70px;
}

.profile-chat-image img {
  width: 70px;
  height: auto;
  text-align: center;
  vertical-align: middle;
}



.notify-badge {
  width: 30px;
  height: 30px;
  overflow: hidden;
  background-color: var(--variant-blue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notify-badge img {
  height: 17px;
  width: auto;
}


.profile-single-page {
  height: 80px;
  width: 80px;
  border-radius: 100%;
}







path,
svg {
  align-self: center;
}


.bg-orange {
  background-color: var(--orange);
}

.text-orange {
  color: var(--orange) !important;
}

.border-orange {
  border: 1px var(--orange) solid;
}

.bg-purple {
  background-color: var(--purple);
}

.border-purple {
  border: 1px var(--purple) solid;
}

.bg-red {
  background-color: var(--red);
}

.border-red {
  border: 1px var(--red) solid;
}

.bg-blue {
  background-color: var(--blue);
}

.border-blue {
  border: 1px var(--blue) solid;
}

.bg-variant-blue {
  background-color: var(--variant-blue);
}


.section-login {
  background-color: var(--variant-blue);
  height: 100vh;
}

.btn-email {
  background-color: #2CC47D;
  border-radius: 0px 0px 0px 20px;
  padding-top: 10px
}

.btn-call {
  border-radius: 0px 0px 20px 0px;
  background-color: #F0953B;
  padding-top: 10px
}


.form-class {
  margin-top: 200px;
  border-radius: 20px 20px 0px 0px;
}

.logo-esteso-fulgeas {
  width: 200px;
  height: auto;
}



.bg-login {
  background: url('./img/customer-img-login.jpg') center center / cover;
  height: 100vh;
}

.form-login div {
  margin-top: 200px;
}

.center-icon {
  margin: auto;

}





/* Navbar */


.navbar {
  background-color: #4A8BC1;
  height: 80px;
  z-index: 1;
}

.menu-bars {
  font-size: 2rem;
  background: none;
  float: right;
  z-index: 1;
}

.nav-menu {
  z-index: 3;
  background-color: #404040;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;

  position: fixed;
  top: calc(80px);
  left: -100%;
  transition: 850ms;
}

.ticketsActive {
  background-color: rebeccapurple !important;
}


.nav-menu>ul {
  width: 100%;
}

.nav-menu>ul>li {
  width: 100%;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}


nav a.active {
  background-color: red;
}


/*Animation*/



.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.widget-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;

}



.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text:hover {
  background-color: var(--blue);
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  font-size: 20pt;
}



span {
  margin-left: 16px;
}

.sidebar-toggle-box {
  float: left;
  height: 80px;
  padding-inline: 30px;

}

.sidebar-toggle-box .icon-reorder {
  cursor: pointer;
  display: inline-block;
  font-size: 20pt;
  margin-top: 15px;
}


.bg-hamburger {
  background-color: #437DAD;
}

.single-icon {
  font-size: 25px;
}

.cont-profile img {
  border-radius: 100%;
  height: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;

}

.border-bottom-light-grey {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px var(--light-grey) solid;
}

.image-profile {
  height: 60px;
  width: 60px;
  font-size: 18px;
}


.notification-profile {
  height: 40px;
  width: 40px;
  font-size: 18px;
  line-height: 40px;
}


div.contentAfter {
  position: relative;
}


.bottom-nav {
  height: 80px;
}


.badge-notifiche {
  font-size: 12px;
  vertical-align: center;
  background-color: var(--orange);
}

.badge-priority {
  font-size: 12px;
}

.bg-dashboard {
  background-color: var(--dark-blue);
}

div.modal-header {
  border-radius: 20px 20px 0px 0px;
}

div.modal-content {
  border-radius: 20px 20px 20px 20px;
}

.bg-progetti {
  background-color: var(--purple);
}

.bg-tickets {
  background-color: var(--light-blue);
}

.bg-notifiche {
  background-color: var(--orange);
}


path,
svg {
  align-self: center;
}




.notification-font {
  font-size: 12px;
}

.ticket-reference::before {
  content: '|';
}

.project-reference::before {
  content: '|';
}


.page-link {
  color: var(--dark-grey);
}

.page-item.active .page-link {
  background-color: var(--dark-blue);
  border: 0;
  color: white;

}


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select,
.form-select:focus,
option,
.page-link:focus,
.btn:focus,
button:focus,
.navbar-toggler:focus,
.uneditable-input:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.form-select:focus {
  border-color: transparent;
}



.centerSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.welcome {
  position: absolute;
  top: 38%;
  color: #fdc511;

}


.text-fulgeas {
  color: #fdc511;
}



/* Status */
.table {
  background-color: transparent;

}




.text-green {
  color: #2CC47D;
}

.text-red {
  color: red;
}

.text-grey {
  color: var(--variant-grey)
}

.table .badge-open {
  background-color: #2CC47D;
}


.table .badge-closed {
  background-color: var(--red)
}


.table .badge-review {
  background-color: var(--orange)
}

.table .badge-working {
  background-color: var(--purple)
}

.badge-mobile-working {
  background-color: var(--purple)
}

.badge-mobile-review {
  background-color: var(--orange)
}

.badge-mobile-closed {
  background-color: var(--red)
}

.badge-mobile-open {
  background-color: #2CC47D;
}

.table .badge-working,
.badge-review,
.badge-closed,
.badge-open {
  font-size: 12px;
}


.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}


.filter-section {
  display: none;
  transition: all 850ms;
}



.text-dot-grey {
  color: gainsboro;
}


input[type="checkbox" i]:disabled {
  cursor: default;
  background-color: red;
  color: red;
  border-color: rgba(118, 118, 118, 0.3);
}

input:disabled {
  cursor: default;
  background-color: red;
  color: red;
  border-color: rgba(118, 118, 118, 0.3);
}


.bg-variant-grey {
  background-color: var(--grey-second);
}

.bg-green {
  background-color: #2CC47D;
}


.logo-custom-navbar {
  height: 50px;
}

.selectSinglePageTicket {
  width: 200px;
  float: left;
  margin-left: 20px;
  padding-top: 0;
  padding-bottom: 0;

}


.border-open,
.border-open:focus {
  border: #2ac1b2 2px solid;
}

.border-working,
.border-working:focus {
  border: #c0ac7a 2px solid;
}

.border-closed,
.border-closed:focus {
  border: #aeaeae 2px solid;
}

.border-review,
.border-review:focus {
  border: #f1c30e 2px solid;
}


.border-priority-low,
.border-priority-low:focus {
  border: #69c95a 2px solid;
}

.border-priority-high,
.border-priority-high:focus {
  border: #38adcb 2px solid;
}


.border-priority-urgent,
.border-priority-urgent:focus {
  border: #e44666 2px solid;

}


.badge-urgent {
  background-color: #e44666;
}

.badge-high {
  background-color: #38adcb;
}

.badge-low {
  background-color: #69c95a;
}



.vcenter-item {
  display: flex;
  align-items: center;
}


.border-input-login,
.border-input-login:focus {
  border: #aeaeae 1px solid;
}


input.loading {
  background: url(https://media.tenor.com/On7kvXhzml4AAAAi/loading-gif.gif) no-repeat center center;
  background-size: 20px;
}



.bg-variant-green {
  background-color: var(--green);
}

.bg-variant-red {
  background-color: var(--variant-red);
}


.upload-attachments-button-ask {
  background-color: transparent;
  color: black;
  cursor: pointer;
  float: right;

}

.progress-value {
  background-image: linear-gradient(to right, var(--blue), var(--green));
}

.images {
  height: 100px;
  width: auto;


}

.images img {
  object-fit: scale-down;
}

.images embed {
  object-fit: scale-down;
}

.video-attachment-format {
  width: 200px;
  object-fit: scale-down;
}

.pdf-attachment-format {
  width: 200px;
  object-fit: scale-down;
}

.img-attachment {
  width: 200px;
  height: auto;
  text-align: center;
}

.download-hover {
  display: none;
  transition: all 250ms;
  background-color: rgba(red, green, blue, 0.9);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.download-wrap:hover .download-hover {
  display: block;
}

.download-wrap:hover .image-different-extension {
  filter: brightness(20%);
}


.image-different-extension {
  transition: all 250ms;
}

.image-different-extension:hover {
  filter: brightness(20%);
  transition: all 250ms;
}


#uploadAttachments:disabled {
  background: transparent;
}

.notification-list-navbar {
  width: auto;
  min-width: 250px;
  max-width: 400px;
  height: auto;
  top: 60px
}


.font-10 {
  font-size: 10px;
}

.rowNotification {
  transition: all 250ms;
}

.rowNotification:hover {
  color: white !important;
  background-color: black;
  transition: all 250ms;
}

.text-black {
  color: black !important;
}

.tdPaginationProjects td {
  border: 0 !important;
}

.pagination {
  justify-content: end !important;
  margin-top: 10px;
}



.fc-prev-button,
.fc-next-button {
  background-color: var(--dark-blue) !important;
  border: 0 !important;
}

.fc-next-button {
  margin-left: 2px !important;
}



.fc-dayGridWeek-button,
.fc-dayGridDay-button,
.fc-dayGridMonth-button {
  background-color: var(--dark-blue) !important;
  border: 0 !important;

}


.statistica {
  height: 500px;
}


.bottom-nav {
  margin-top: 200px;
}


.notification-pag {
  height: 210.22px !important;
}


.ticketsSidebar {
  display: flex;
  color: #e1e9fc;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.25s;

}

.ticketsSidebar:hover {
  background-color: var(--light-blue);

}

.bg-ticket {
  background-color: var(--light-blue);
}



.drag-over {
  background-color: var(--grey-second);
  color: white;
  transition: all 250ms;
}

.drag-drop-container {
  transition: all 250ms;
}

.border-profile {
  border: 1px white solid;
}


textarea {
  min-height: 100px;
  height: auto;
}

.messaggi li {
  list-style: auto;
}

.ql-editor {
  min-height: 200px;
  overflow: none;
}

.rdtBtn {
  margin: 0;
}

.rdtCounter .rdtCount {
  height: auto !important;
}

.image-profile-navbar {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}

.image-profile-navbar-mobile {
  height: 85px;
  width: 85px;
  border-radius: 100%;
}


.modal-backdrop.show {
  z-index: 3;
}

div .modal-body,
div .modal-header,
.modal-content,
.modal-dialog,
div .modal-footer {
  z-index: 999999999999 !important;
}

.profile-image-navbar {
  cursor: pointer;
}

#formRispondiCreateTicket .ql-editor {
  height: 350px !important;
}




.freccia-reorder:hover svg {
  transform: rotate(180deg);
  transition: all 250ms;
}

.freccia-reorder svg {
  transition: all 250ms;
}


.pill-start {
  border-radius: 5px 0px 0px 5px !important;
}

.pill-end {
  border-radius: 0px 5px 5px 0px !important;
}


.fade {
  opacity: 0.9 !important;
}

.offcanvas {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: white;
}

.profile-single-page h2 {
  line-height: 80px;
}


.container {

  transition: all 250ms;
}


.rounded-class {
  border-radius: 20px 20px 20px 20px;
}




@media (min-width: 992px) {
  .containerWithSidebar {
    margin-left: 230px;
    width: auto;
    overflow: scroll !important;
    white-space: nowrap;
    display: block;
    padding-left: 20px !important;
    padding-right: 20px !important;
    word-break: normal;
  }

  .containerWithSidebarSingle {
    margin-left: 230px;
    width: auto;
    overflow: scroll !important;
    display: block;
    padding-left: 20px !important;
    padding-right: 20px !important;
    word-break: normal;
  }


}


@media (max-width: 992px) {

  .containerWithSidebar {
    margin-left: auto;
    overflow: unset;
  }

  .containerWithSidebarSingle {
    margin-left: auto;
    overflow: unset;
  }

  .ql-toolbar span.ql-formats {
    display: none;
  }

  div.ql-toolbar {
    border-bottom: 0 !important;
  }

  .profile-single-page {
    width: 3em;
    height: 3em;
    text-align: center;
  }

  .profile-single-page-span {
    width: 3em;
    height: 3em;
    text-align: center;
    border-radius: 100%;
  }

  .notify-badge {
    width: 1.4em;
    height: 1.4em;
    top: 40px;
  }

  .notify-badge img {
    width: 15px;
    height: 15px;

  }

  .prime-cards {
    width: 15em;
    font-size: 10pt !important;
  }


  .prime-cards span {
    font-size: 14pt !important;
  }

}

hr {
  opacity: 1;
}





#offcanvasMobile {
  z-index: 99999 !important;
}


.outline-orange {
  outline: 1px var(--orange) solid;
}


#searchDropdown {
  z-index: 999999 !important;
}

.text-purple {
  color: var(--purple);
}


.fader {
  z-index: 0 !important;
}



@media (max-width: 407px) {


  .prime-cards {
    width: 16em;
    font-size: 8pt !important;
  }


  .prime-cards span {
    font-size: 12pt !important;
  }



}


@media (max-width: 246px) {


  div.profile-single-page {
    display: none !important;
  }


}


@media (min-width: 247px) {


  div.profile-single-page {
    display: flex !important;
  }


}


.badge-add-ticket {
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  font-size: 15pt;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -10px;
  border: 2px var(--bs-dark) solid;
}





@media (max-width: 766px) {

  .fc-header-toolbar {
    display: grid !important;
    justify-content: center !important;
  }

  .fc-toolbar-chunk div {
    display: flex !important;
    align-items: center !important;
    gap: 20px !important;
  }

  .fc-toolbar-chunk {
    display: flex !important;
    justify-content: center !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  th {
    width: 100% !important;
  }

}


.fc-event-time {
  display: none !important;
}

.fc-daygrid-event-dot {
  display: none !important;
}

div.fc-event-title {
  background-color: var(--orange);
  text-align: center !important;
  color: white !important;
}



.badge-add-ticket-navbar {
  width: 20px;
  height: 20px;
  font-size: 10pt;
  position: absolute;
  top: 0;
  color: var(--dark-blue);
  right: -10px;
  border: 2px var(--dark-blue) solid;
  padding: 5px;
}


mark {
  background-color: var(--yellow-fulgeas);
  color: black;
}


.watcher-profile {
  aspect-ratio: 1/1;
  padding: 10px;
}

.watcher-profile-img {
  width: 0px;
  height: auto;
}


.fulgeas-staff {
  width: 1vw;
  height: 1vw;
}

.fulgeas-staff img {
  width: 0.6vw;
  height: 0.6vw;
}


.no-avatar-letters-ticket {
  font-size: 1.2vw;

}


.image-profile-watcher-ticket {
  height: 2.5vw;
  width: 2.5vw;
  border-radius: 100%;
}


.watcher-ticket-info {
  font-size: 0.8vw;
}



.list-watchers-title {
  font-size: 8vw;
}


.container-file img {
  max-height: 40vw;
  width: auto;
}

.container-preview-file img {
  max-height: 10vh;
  max-width: auto;
}

.media-of-ticket-title {
  font-size: 0.8vw;
}